const element = $('.lightbox');

element.on('click', () => {
    $('.backdrop').addClass('backdrop-open');
    element.addClass('open-lightbox');
    $('.close-open-lightbox').addClass('close-lightbox');
})

$('.backdrop').on('click', () => {
    $('.backdrop').removeClass('backdrop-open');
    element.removeClass('open-lightbox');
    $('.close-open-lightbox').removeClass('close-lightbox');
})

$('.close-open-lightbox').on('click', () => {
    $('.backdrop').removeClass('backdrop-open');
    element.removeClass('open-lightbox');
    $('.close-open-lightbox').removeClass('close-lightbox');
})

