$(window).scroll(function(){
    if ($(this).scrollTop() > 200) {
        $('.header').addClass("fixed");
        // add padding top to show content behind navbar
        // $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
    }else{
        $('.header').removeClass("fixed");
        // remove padding top from body
        // $('body').css('padding-top', '0');
    }
});