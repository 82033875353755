const name = $('#name');
const email = $('#e-mail');

const testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
let valName = 10; // min length for name

const validation = (test, event) => {
    if (test.test($(event.target).val())) {
        $(event.target).removeClass('error');
        $('.button').removeAttr('disabled');
    } else {
        $(event.target).addClass('error');
        $('.button').attr('disabled','disabled');
    }
}


const keyVal = (element,length,e, len) => {
    const key = e.keyCode; // const {key} = event; ES6+

    if (len === 1) {
        if (key === 13 || key === 8) {
            valMsg++;
            if (valMsg >= length) { valMsg = length; }
            element.html(valMsg);
        } else {
            valMsg--;
        }
        if(valMsg <= 0) {
            $(e.target).removeClass('error-input');
            $(e.target).siblings().removeClass('error-label');
            $(e.target).siblings().removeClass('form-alert');
            $('.button').removeAttr('disabled');
        } else {
            $('.button').attr('disabled','disabled');
            element.html(valMsg);
        }
    } else {
        if (key === 13 || key === 8) {
            valName++;
            if (valName >= length) { valName = length; }
            element.html(valMsg);
        } else {
            valName--;
        }
        if(valName <= 0) {
            $(e.target).removeClass('error-input');
            $(e.target).siblings().removeClass('error-label');
            $(e.target).siblings().removeClass('form-alert');
            $('.button').removeAttr('disabled');
        } else {
            $('.button').attr('disabled','disabled');
            element.html(valName);
        }
    }
}

// name length validation
name.on('keydown', (e) => {
    keyVal($('.typeNumberName'), 10, e, 0);
})

email.focusout((e) => {
    validation(testEmail, e);
})